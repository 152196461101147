import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

// import i18n from "@/libs/i18n";
import VueCountryCode from "vue-country-code";
import "@/libs/sweet-alerts";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import i18n from "@/libs/i18n";
import VueSweetalert2 from "vue-sweetalert2";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import dotenv from "dotenv";
dotenv.config();
import helpers from "@/composables/helper";

Vue.use({
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueSweetalert2);

// Composition API
Vue.use(VueCompositionAPI);
Vue.use(VueCountryCode);
Vue.component("content-not-view", contentNotView);
// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
