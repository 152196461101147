import Swal from "sweetalert2";
import i18n from "@/libs/i18n";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  /**
   * @param form with type object
   * @returns {FormData}
   */
  prepareFormData(form) {
    const formData = new FormData();
    for (const key in form) {
      if (form[key] !== null) {
        formData.append(key, form[key]);
      }
    }
    return formData;
  },
  makeToast(variant = "success", title, body) {
    toast({
      component: ToastificationContent,
      position: "top-right",
      props: {
        title: i18n.t(title),
        icon:
          variant === "success"
            ? "CheckCircleIcon"
            : variant === "danger"
            ? "XCircleIcon"
            : "AlertTriangleIcon",
        variant: variant,
        text: i18n.t(body),
      },
    });
  },
  intOnlyFormater(text) {
    const validCharactersRegex = /[0-9]/g;
    const validCharactersArray = text.match(validCharactersRegex);
    if (
      Array.isArray(validCharactersArray) &&
      validCharactersArray.length < 10
    ) {
      return validCharactersArray ? validCharactersArray.join("") : "";
    } else {
      if (
        Array.isArray(validCharactersArray) &&
        validCharactersArray.length > 10
      ) {
        validCharactersArray.length = 10;
        return validCharactersArray ? validCharactersArray.join("") : "";
      } else {
        return validCharactersArray ? validCharactersArray.join("") : "";
      }
    }
  },
  handleError(error) {
    if (error.response.data.message && !error.response.data.errors) {
      this.makeToast(
        "danger",
        error.response.data.message,
        i18n.t("g.send.errorTitle")
      );
    } else if (error.response.data.errors) {
      for (const key in error.response.data.errors) {
        this.makeToast(
          "danger",
          error.response.data.errors[key][0],
          i18n.t("g.send.errorTitle")
        );
      }
    } else {
      this.makeToast(
        "danger",
        i18n.t("g.send.errorMessage"),
        i18n.t("g.send.errorTitle")
      );
    }
  },

  filterImages(placeholderImage, name, data = null) {
    if (data) {
      for (let image of data) {
        if (image.name === name) {
          return image.path;
        }
      }
    }
    return placeholderImage;
  },
  translateValidations(error, key) {
    return i18n
      .t(`g.alert.${error.replace(i18n.t(key), "template")}`)
      .replace("&&", i18n.t(key));
  },

  sweetAlertConfirm(
    title,
    text,
    icon,
    confirmButtonText,
    cancelButtonText,
    callback
  ) {
    Swal.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText,
      cancelButtonText,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
        this.makeToast(
          "success",
          i18n.t("g.boardStyles/deleted_successfully"),
          i18n.t("g.The item was deleted successfully")
        );
      }
    });
  },

  slugify(text) {
    if (text)
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w\-]+/g, "")
        .replace(/\-\-+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
  },

  uiAvatar(name) {
    return `https://ui-avatars.com/api/?name=${this.slugify(
      name
    )}&background=random`;
  },

  dateTimeConvertFunction(time) {
    const timestampDate = new Date(time);
    const currentDate = new Date();
    const timeDifference = currentDate - timestampDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  },
  formatDateToDayMonth(inputDate, isAr = false) {
    const date = new Date(inputDate);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthNamesTranslated = [
      "يناير",
      "فبراير",
      "مارس",
      "ابريل",
      "مايو",
      "يونيو",
      "يوليو",
      "اغسطس",
      "سبتمبر",
      "اكتوبر",
      "نوفمبر",
      "ديسيمبر",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const arr = isAr ? monthNamesTranslated : monthNames;
    const formattedDate = `${day} ${arr[monthIndex]}`;

    return formattedDate;
  },
  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  },
};
