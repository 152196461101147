export default [
  {
    path: '/grades',
    name: 'gradesList',
    component: () => import('@/views/grades/gradesList/index.vue'),
    meta: {
      pageTitle: 'g.routes.grades_list',
      breadcrumb: [
        {
          text: 'g.routes.grades_list',
          active: true,
        },
      ],
    }
  },
  {
    path: '/grades/add',
    name: 'addGrade',
    component: () => import('@/views/grades/addGrade/index.vue'),
    meta: {
      pageTitle: 'g.routes.add_grades',
      breadcrumb: [
        {
          text: 'g.routes.add_grades',
          active: true,
        },
      ],
    }
  },
  {
    path: '/grades/:id/edit',
    name: 'editGrade',
    component: () => import('@/views/grades/editGrade/index.vue'),
    meta: {
      pageTitle: 'g.routes.update_grades',
      breadcrumb: [
        {
          text: 'g.routes.update_grades',
          active: true,
        },
      ]
    }
  }
]
