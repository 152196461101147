import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import BreadCrumb from "./breadCrumb";
import userData from "./userData";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    BreadCrumb,
    userData,
  },
  strict: process.env.DEV,
});
