export default [
  {
    path: "/coupons",
    name: "couponsList",
    component: () => import("@/views/coupons/list/index.vue"),
    meta: {
      pageTitle: "g.routes.coupons",
      breadcrumb: [
        {
          text: "g.routes.coupons",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-coupon",
    name: "AddCoupon",
    component: () => import("@/views/coupons/add/index.vue"),
    meta: {
      pageTitle: "g.routes.add_coupons",
      breadcrumb: [
        {
          text: "g.routes.coupons",
          active: false,
        },
        {
          text: "g.routes.add_coupons",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-coupon/:id",
    name: "EditCoupon",
    component: () => import("@/views/coupons/edit/index.vue"),
    meta: {
      pageTitle: "g.routes.edit_coupons",
      breadcrumb: [
        {
          text: "g.routes.coupons",
          active: false,
        },
        {
          text: "g.routes.edit_coupons",
          active: true,
        },
      ],
    },
  },
];
