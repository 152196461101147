export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/authentication/login/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: () => import('@/views/authentication/forgetPassword/ForgetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
