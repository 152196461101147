export default [
    {
      path: "/list-of-reports",
      name: "ReportsList",
      component: () => import("@/views/report/reportList.vue"),
    },
    {
      path: "/report/:id",
      name: "viewReport",
      component: () => import("@/views/report/viewReport.vue"),
    },
  
  ];
  