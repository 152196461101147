export default [
    {
        path: '/assignment-types',
        name: 'assignmentTypesList',
        component: () => import('@/views/assignmentTypes/AssignmentTypesList/index.vue'),
        meta: {
            pageTitle: 'g.routes.assignment_types',
            breadcrumb: [
                {
                    text: 'g.routes.assignment_types',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/assignment-types/add-assignment-types',
        name: 'addAssignmentType',
        component: () => import('@/views/assignmentTypes/AddAssignmentTypes/index.vue'),
        meta: {
            pageTitle: 'g.routes.add_assignment_types',
            breadcrumb: [
                {
                    text: 'g.routes.assignment_types',
                    to: '/assignment-types',
                },
                {
                    text: 'g.routes.add_assignment_types',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/assignment-types/:id/edit',
        name: 'editAssignmentType',
        component: () => import('@/views/assignmentTypes/EditAssignmentTypes/index.vue'),
        meta: {
            pageTitle: 'g.routes.update_assignment_types',
            breadcrumb: [
                {
                    text: 'g.routes.assignment_types',
                    to: '/assignment-types',
                },
                {
                    text: 'g.routes.update_assignment_types',
                    active: true,
                },
            ],
        }
    }
];
