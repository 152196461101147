export default [
  {
    path: "/list-subjects",
    name: "SubjectsList",
    component: () => import("@/views/subjects/listOfSubjects.vue"),
  },
  {
    path: "/subject",
    name: "AddSubjects",
    component: () => import("@/views/subjects/addSubject.vue"),
  },
  {
    path: "/subject/:id",
    name: "editSubjects",
    component: () => import("@/views/subjects/addSubject.vue"),
  },
];
