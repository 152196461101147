export default [
  {
    path: "/role-list",
    name: "ListRoles",
    component: () => import("@/views/role/listRoles.vue"),
    meta: {
      pageTitle: "g.role-list",
      breadcrumb: [
        {
          text: "g.role-list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-role",
    name: "AddRole",
    component: () => import("@/views/role/addRole.vue"),
    meta: {
      pageTitle: "g.add-role",
      breadcrumb: [
        {
          text: "g.add-role",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-role/:id",
    name: "EditRole",
    component: () => import("@/views/role/addRole.vue"),
    meta: {
      pageTitle: "g.edit-role",
      breadcrumb: [
        {
          text: "g.add-role",
          active: true,
        },
      ],
    },
  },
];
