export default [
    {
      path: "/users/users-list",
      name: "UsersList",
      component: () => import("@/views/users/usersList"),
      meta: {
        pageTitle: "g.users_list",
        breadcrumb: [
          {
            text: "g.users_list",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/add-user",
      name: "AddUser",
      component: () => import("@/views/users/addUser"),
      meta: {
        pageTitle: "g.add_user",
        breadcrumb: [
          {
            text: "g.add_user",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/edit-user/:id",
      name: "EditUser",
      component: () => import("@/views/users/addUser"),
      meta: {
        pageTitle: "g.edit_user",
        breadcrumb: [
          {
            text: "g.edit_user",
            active: true,
          },
        ],
      },
    }
  ];
  