export default [
  {
    path: '/board-styles',
    name: 'boardStylesList',
    component: () => import('@/views/boardStyles/BoardStylesList/index.vue'),
    meta: {
      pageTitle: 'g.board_styles',
      breadcrumb: [
        {
          text: 'g.board_styles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/board-styles/add-style',
    name: 'addBoardStyle',
    component: () => import('@/views/boardStyles/AddBoardStyle/index.vue'),
    meta: {
      pageTitle: 'g.add_board_style',
      breadcrumb: [
        {
          text: 'g.add_board_style',
          active: true,
        },
      ],
    }
  },
  {
    path: '/board-styles/:id/edit',
    name: 'editBoardStyle',
    component: () => import('@/views/boardStyles/EditBoardStyle/index.vue'),
    meta: {
      pageTitle: 'g.edit_board_style',
      breadcrumb: [
        {
          text: 'g.edit_board_style',
          active: true,
        }
      ]
    }
  }
]
