export default [
  {
    path: "/education-years",
    name: "EducationYearsList",
    component: () => import("@/views/eduYears/eduYearList/index.vue"),
    meta: {
      pageTitle: "g.education_years_list",
      breadcrumb: [
        {
          text: "g.education_years_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-education-year",
    name: "AddEducationYear",
    component: () => import("@/views/eduYears/addEduYear/index.vue"),
    meta: {
      pageTitle: "g.add_education_year",
      navActiveLink: "EnrolledCourse",
      breadcrumb: [
        {
          text: "g.add_education_year",
          active: true,
        },
      ],
    },
  },
  {
    path: "/show-education-year/:id",
    name: "ShowEducationYear",
    component: () => import("@/views/eduYears/showEduYear/index.vue"),
    meta: {
      pageTitle: "g.show_education_year",
      navActiveLink: "EnrolledCourse",
      breadcrumb: [
        {
          text: "g.show_education_year",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-education-year/:id",
    name: "EditEducationYear",
    component: () => import("@/views/eduYears/addEduYear/index.vue"),
    meta: {
      pageTitle: "g.edit_education_year",
      navActiveLink: "EnrolledCourse",
      breadcrumb: [
        {
          text: "g.edit_education_year",
          active: true,
        },
      ],
    },
  }
];
