import Vue from "vue";
import VueRouter from "vue-router";
import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isUserLoggedIn,
} from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";

import grades from "@/router/routes/grades";
import boardStyles from "@/router/routes/boardStyles";
import assignmentTypes from "@/router/routes/assignmentTypes";
import report from "@/router/routes/report";
import teachersRequests from "@/router/routes/teachersRequests";
import coupons from "@/router/routes/copouns";
import currencies from "@/router/routes/currencies";
import classrooms from "./routes/classrooms";
import users from "./routes/users";
import personal from "./routes/personal";
import authentication from "./routes/authentication";
import reportReason from "./routes/reportReason";
import eduYear from "./routes/edu-year";
import subjects from "./routes/subjects";
import eduLevels from "./routes/eduLevel";
import country from "./routes/country";
import role from "./routes/role";
import parents from "@/router/routes/parents";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        navActiveLink: "home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    ...currencies,
    ...authentication,
    ...users,
    ...coupons,
    ...personal,
    ...classrooms,
    ...reportReason,
    ...eduYear,
    ...subjects,
    ...grades,
    ...report,
    ...boardStyles,
    ...eduLevels,
    ...role,
    ...country,
    ...assignmentTypes,
    ...teachersRequests,
    ...parents,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: "Login" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
