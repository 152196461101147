export default [
  {
    path: "/list-teachers-requests",
    name: "teachersRequests",
    component: () => import("@/views/teachersRequests/list/index.vue"),
  },
  {
    path: "/teachers-requests/:id",
    name: "showTeachersRequest",
    component: () => import("@/views/teachersRequests/show/index.vue"),
  },
];
