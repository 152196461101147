export default [
  {
    path: "/report-reasons",
    name: "ReportReasonsList",
    component: () => import("@/views/reportReason/reportReasonlist.vue"),
    meta: {
      navActiveLink: "home",
    },
  },
  {
    path: "/add-report-reason",
    name: "AddReportReasons",
    component: () => import("@/views/reportReason/addReportReason.vue"),
    meta: {
      navActiveLink: "home",
    },
  },
  {
    path: "/edit-report-reason/:id",
    name: "editReportReasons",
    component: () => import("@/views/reportReason/addReportReason.vue"),
    meta: {
      navActiveLink: "home",
    },
  },
];
