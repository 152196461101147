import axiosIns from "@axios";

export default {
  state: {
    userId: null,
    userData: {},
    is_blocked: false,
    role: null,
    permissions: [],
    sub_Permission: {},
    sub_Permissions: {},
  },
  getters: {},
  mutations: {},
  actions: {
    FETCH_DATA_USER({ state }) {
      const dataInSession = window.sessionStorage.getItem("userData");
      if (!dataInSession) {
        axiosIns
          .get(`auth/admin/profile`)
          .then((res) => {
            if (res.status === 200) {
              const { user } = res.data.data;
              state.userData = user;
              state.userId = user.id;
              state.is_blocked = user.is_blocked;
              state.role = user.role;
              state.permissions = user.roles[0].permissions;
              window.sessionStorage.setItem("userData", JSON.stringify(user));
              window.localStorage.setItem("userData", JSON.stringify(user));
              if (user.is_blocked) {
                localStorage.clear();
                window.location.href = "/";
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const user = JSON.parse(dataInSession);
        state.userData = user;
        state.userId = user.id;
        state.is_blocked = user.is_blocked;
        state.role = user.role;
        state.permissions = user.roles[0].permissions;
      }
    },

    /**
     * GET_PERMISSION({ state }, payload)
     * @param payload  {string}
     */
    GET_PERMISSION: ({ state }, payload) => {
      if (typeof payload !== "string") {
        throw new Error("payload must be an string");
      }

      const permission = state.permissions.find(
        (item) => item.name === payload
      );
      permission?.subPermissions?.forEach(
        (item) => (state.sub_Permission[item.name] = item.is_checked)
      );
    },

    /**
     * GET_PERMISSIONS({ state }, payload)
     * @param payload  {array}
     */
    GET_PERMISSIONS: ({ state }, payload) => {
      if (!Array.isArray(payload)) {
        throw new Error("payload must be an array");
      }

      const filteredPermissions = state.permissions.filter((item) =>
        payload.includes(item.name)
      );

      if (filteredPermissions.length) {
        filteredPermissions.forEach((permission) => {
          let subPermissions = {};
          for (const subPermission of permission.subPermissions) {
            subPermissions[subPermission.name] = subPermission.is_checked;
          }
          state.sub_Permissions[permission.name] = subPermissions;
        });
      }
    },
  },
};
