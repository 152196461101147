export default [
  {
    path: "/Educations-levels",
    name: "EducationLevelsList",
    component: () => import("@/views/edulevel/listOfEduLevel.vue"),
    meta: {
      pageTitle: "g.Education-level",
      breadcrumb: [
        {
          text: "g.Education-level",
          active: true,
        },
        {
          text: "g.list-Education-level",
          active: true,
        },
      ],
    },
  },
  {
    path: "/Education-level/:id",
    name: "EditEducationLevel",
    component: () => import("@/views/edulevel/addEduLevel.vue"),
    meta: {
      pageTitle: "g.Education-level",
      breadcrumb: [
        {
          text: "g.Education-level",
          active: true,
        },
        {
          text: "g.list-Education-level",
          active: true,
        },
      ],
    },
  },
  {
    path: "/Education-level",
    name: "AddEducationLevel",
    component: () => import("@/views/edulevel/addEduLevel.vue"),
    meta: {
      pageTitle: "g.Education-level",
      breadcrumb: [
        {
          text: "g.Education-level",
          active: true,
        },
        {
          text: "g.list-Education-level",
          active: true,
        },
      ],
    },
  },
];
